import React from 'react'
import Image from "next/image"
import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material'
import { useRouter } from 'next/router';

type Props = {}

export default function BannerPartner({ }: Props) {

    const isMobile = useMediaQuery('(max-width:600px)');
    const router = useRouter()


    const handleToRegisverrify = () => {
        // router.push("/community/verify-place")
        window.location.href = '/community/verify-place';
    }

    const handle = () => {
        // router.push("/community/partner")
        window.location.href = '/community/partner';
    }

    return (
        <>
            {isMobile ? (
                <>

                    <Box
                        sx={{
                            position: 'relative',
                            width: '100%',
                            height: 'auto',
                            borderRadius: '14px',
                            overflow: 'hidden',
                            mt: "15px"
                        }}
                    >
                        <Image
                            src="/assets/images/banner/bannerpartner-mobile.png"
                            alt="Banner Image"
                            layout="responsive"
                            width={100}
                            height={50}
                            style={{ objectFit: 'cover' }}
                            loading="lazy"
                            placeholder="empty"
                            blurDataURL={`/assets/images/banner/bannerpartner-mobile.png`}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                top: "-12px",
                                left: 0,

                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                                textAlign: 'center',

                            }}
                        >

                            <Stack direction={"row"}>
                                <Button variant="contained" color="primary" sx={{ mr: 1 }}
                                    onClick={handleToRegisverrify}
                                >
                                    สมัครเลย
                                </Button>
                                <Button onClick={handle} variant="outlined" color="primary">
                                    สิทธิประโยชน์มากมาย
                                </Button>
                            </Stack>
                        </Box>
                    </Box>

                </>

            ) : (
                <>
                    <Box
                        sx={{
                            position: 'relative',
                            width: '100%',
                            height: 'auto',
                            borderRadius: '14px',
                            overflow: 'hidden',
                            mt: "15px"
                        }}
                    >
                        <Image
                            src="/assets/images/banner/bannerpartner.png"
                            alt="Banner Image"
                            layout="responsive"
                            width={100}
                            height={50}
                            style={{ objectFit: 'cover' }}
                            loading="lazy"
                            placeholder="empty"
                            blurDataURL={`/assets/images/banner/bannerpartner.png`}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                top: "-12px",
                                left: 0,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',

                                color: 'white',
                                textAlign: 'center',
                                p: 2,
                            }}
                        >

                            <Stack direction={"row"}>

                                <Button variant="contained" color="primary" sx={{ mr: 1 }}
                                        onClick={handleToRegisverrify}
                                >
                                    สมัครเลย
                                </Button>
                                <Button onClick={handle} variant="outlined" color="primary">
                                    สิทธิประโยชน์มากมาย
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                </>
            )}



        </>
    )
}