import React, { useRef } from "react";
import { Typography, Box, CardContent, useMediaQuery, IconButton } from "@mui/material";
import Image from "next/legacy/image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useRouter } from "next/router";
import { ApiResponse } from "../../interfaces/product/product-type";
import useResponsiveItems from "@/hooks/useResponsiveItems";
import Grid from "@mui/material/Unstable_Grid2";

interface IProductType {
  dataSet: ApiResponse[];
}

export default function ProductType({ dataSet }: IProductType) {

  const limitDisplay = useResponsiveItems({
    xsCount: 4,
    smCount: 4,
    mdCount: 6,
    lgCount: 10,
    xlCount: 12,
    xxlCount: 16,
  });


  const router = useRouter();
  const isMobile = useMediaQuery("(max-width:800px)");
  const scrollContainer = useRef<HTMLDivElement>(null);
  

  const handleScroll = (direction: "left" | "right") => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollBy({
        left: direction === "left" ? -100 : 100,
        behavior: "smooth",
      });
    }
  };

  const formatName = (name: string) => {
    if (name.length > 11) {
      return name.slice(0, 11) + "\n" + name.slice(11);
    }
    return name;
  };

  const handleChange = (newValue: string) => {
    const currentPath = "/search/allsearch";
    const currentQuery = { ...router.query, keyword: newValue, category_id: '', limit: limitDisplay };
    router.push(
      {
        pathname: currentPath,
        query: currentQuery,
      },
      undefined,
      { shallow: true }
    );
  };

  return (
    <>
      {isMobile ? (
        <Box sx={{ bgcolor: "#f8f8f8", borderRadius: "14px", mt: "15px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflowX: "auto",
              whiteSpace: "nowrap",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <IconButton
              onClick={() => handleScroll("left")}
              disableFocusRipple
              disableRipple
              sx={{
                fontSize: "12px",
                bgcolor: "#FFFFFF",
                borderRadius: "4px",
                ml: "5px",
              }}
              aria-label="Scroll left"
              title="Scroll left"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </IconButton>

            <Box
              ref={scrollContainer}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 3,
                overflowX: "auto",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }}
            >
              {dataSet.map((item: any, idx: number) => (
                <Box
                  onClick={() => handleChange(item.pet_title || "")}
                  key={idx}>
                  <Image
                    src={item.pet_icon_image as string}
                    alt={item.pet_icon_image as string}
                    width={70}
                    height={70}
                    // layout="fixed"
                    objectFit="cover"
                    // priority
                    style={{
                      objectFit: 'cover',
                      borderRadius: '50%',                      
                    }}
                    loading="lazy"
                    placeholder="empty"
                    blurDataURL={item.pet_icon_image as string}
                  />
                  <Typography
                    onClick={() => handleChange(item.pet_title || "")}
                    sx={{
                      color: "#717171",
                      whiteSpace: "pre-line",
                      textAlign: "center",
                      fontSize: "12px",
                      "&:hover": {
                        color: "#000000",
                        "& h3": {
                          fontFamily: "NotoSansThai-SemiBold",
                        },
                      },
                      cursor: "pointer",
                    }}
                  >
                    {formatName(item.pet_title || "")}
                  </Typography>
                </Box>
              ))}
            </Box>

            <IconButton
              onClick={() => handleScroll("right")}
              disableFocusRipple
              disableRipple
              sx={{
                fontSize: "12px",
                bgcolor: "#FFFFFF",
                borderRadius: "4px",
                mr: "5px",
              }}
              aria-label="Scroll right"
              title="Scroll right"
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <CardContent sx={{ bgcolor: "#f8f8f8", borderRadius: "14px", mt: "20px", pb: "20px" }}>
          <Grid
            sx={{
              // display: "flex",
              // flexWrap: "wrap",
              justifyContent: "center",
              gap: { xs: 1, sm: 2, md: 4, lg: 8 },
            }}
            container
          >
            {dataSet?.map((item: any, idx: number) => (
              <Grid
                // sm={3} md={1} lg={auto}
                
                onClick={() => handleChange(item.pet_title || "")}
                key={idx}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  width: 100,
                  "& img": {
                    width: "100%",
                    height: "auto",
                  },
                  cursor: "pointer",
                  // border: "1px solid red"
                }}
              >
                <Image
                  src={item.pet_icon_image as string}
                  alt={item.pet_icon_image as string}
                  width={100}
                  height={100}
                  // layout="fixed"
                  objectFit="cover"
                  // priority
                  style={{
                    objectFit: 'cover',
                    borderRadius: '8px',
                  }}
                  loading="lazy"  // Lazy load for images off-screen
                  placeholder="empty"
                  blurDataURL={item.pet_icon_image as string} // A low-quality base64 version
                />
                <Typography
                  sx={{
                    color: "#717171",
                    mt: 1,
                    mb: 1,
                    fontSize: 16,
                    "&:hover": {
                      color: "#000000",
                      "& h3": {
                        fontFamily: "NotoSansThai-SemiBold",
                      },
                    },
                  }}
                >
                  {formatName(item.pet_title || "")}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      )}
    </>
  );
}
