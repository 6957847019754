import { Button, Grid, Typography } from "@mui/material";
import he from "he";
import React from "react";
import CountdownCampaigncomingSoonTime from "./countdownCampaigncomingSoonTime";
import Productcampaigncomingsoon from "../../components/campaign/productcampaigncomingsoon";
import router from "next/router";
import { isProduction } from "@/config";
import { BreadcrumbJsonLd } from "next-seo";

export default function campaigncomingsoon({
  datacampaignComingSoon,
  dataBannerAds,
}: any) {
  const currentUrl = typeof window !== "undefined" ? window.location.href : "";

  return (
    <>
      {datacampaignComingSoon === null ? (
        <></>
      ) : (
        <>
          <Grid container sx={{ mt: 3, alignItems: "center" }}>
            <Grid
              xs={12}
              sm={7}
              md={7}
              lg={7}
              sx={{ textAlign: { xs: "center", sm: "left" } }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: "16px", md: "25px" },
                  fontFamily:
                    "NotoSans-SemiBold, NotoSansThai-SemiBold !important",
                  mt: { xs: "5px", md: "0px" },
                  mb: 1,
                }}
              >
                {he.decode(datacampaignComingSoon?.campaign_name)}
              </Typography>
            </Grid>
            <Grid
              xs={12}
              sm={5}
              md={5}
              lg={5}
              sx={{ textAlign: { xs: "center" } }}
            >
              <CountdownCampaigncomingSoonTime
                datacampaignComingSoon={datacampaignComingSoon}
                alignment="right"
              />
            </Grid>
          </Grid>
          <Productcampaigncomingsoon
            datacampaignComingSoon={datacampaignComingSoon}
            dataBannerAds={dataBannerAds}
          />
          <Button
            variant="outlined"
            disableRipple
            sx={{
              borderColor: "#DEE2E6",
              width: "300px",
              color: "#292929",
              "&:hover": {
                color: "#DB5A00",
                backgroundColor: "#ffffff",
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
              mt: "15px",
            }}
            onClick={() => {
              router.push("/campaign/campaigncomingsoon/");
            }}
          >
            <Typography>ดูเพิ่มเติม</Typography>
          </Button>
        </>
      )}
    </>
  );
}
