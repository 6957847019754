import BannerPartner from '@/components/cards/banner-partner';
import Image from 'next/image';
import React from 'react';
import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery
  } from '@mui/material';
import { useRouter } from 'next/router';

type Props = {}

export default function index({}: Props) {

  return (
      <BannerPartner />
  )
}