import { Card, Box, CardContent, Typography, Stack, Chip, CardActions, Rating, Grid, useMediaQuery, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Link from "next/link";
import Image from "next/image";
import Productcampaigncomingsoonmobile from '../campaign/productcampaigncomingsoonmobile';
import router from 'next/router';
import { isProduction } from '@/config';

interface ProductItem {
    item_id: string;
    item_name: string;
    affiliation: string | null;
    coupon: string | null;
    discount: number;
    index: number;
    item_brand: string | null;
    item_category: string | null;
    item_category2: string | null;
    item_category3: string | null;
    item_category4: string | null;
    item_category5: string | null;
    item_list_id: string;
    item_list_name: string;
    item_variant: string | null;
    location_id: string | null;
    price: number;
    quantity: number;
}

export default function Productcampaigncomingsoon({
    datacampaignComingSoon,
    dataBannerAds,
}: any) {
    const product = datacampaignComingSoon?.product
    const imgDefault = "/assets/images/logo/logomaboom.png"
    const isLargerThan1920 = useMediaQuery('(min-width:1920px)');
    const isLargerThan2160 = useMediaQuery('(min-width:2160px)');
    const isLargerThan2500 = useMediaQuery('(min-width:2500px)');
    const dataImageCampaign = dataBannerAds?.find((campaign: any) => campaign.ads_position === 1);
    const [visibleProducts, setVisibleProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const updateVisibleProducts = () => {
            const width = window.innerWidth;
            let numberOfCards;

            if (width >= 2160) {
                numberOfCards = 6;
            } else if (width >= 1920) {
                numberOfCards = 5;
            } else if (width >= 1439) {
                numberOfCards = 4;
            } 
            else if (width <= 1309) {
                numberOfCards = 4;
            } 
            else {
                numberOfCards = product.length;
            }

            setVisibleProducts(product.slice(0, numberOfCards));
        };


        updateVisibleProducts();


        window.addEventListener('resize', updateVisibleProducts);


        return () => {
            window.removeEventListener('resize', updateVisibleProducts);
        };
    }, [product]);


    const formatPrice = (price: number, digits: number = 2): number =>
        parseFloat(price.toFixed(digits));

    const itemList = {
        itemListId: "comingsoon_campaign_products",
        itemListName: "สินค้า/บริการ แคมเปญที่กำลังจะถึง",
    };

    const handleProductClick = (
        item: any,
        itemList: { itemListId: string; itemListName: string }
    ): void => {
        if (!item) {
            if (!isProduction) {
                console.warn("No product data available.");
            }
            return;
        }

        // Map product data and format it
        const items: ProductItem[] = Array.isArray(datacampaignComingSoon?.product)
            ? datacampaignComingSoon?.product?.map((item: any, idx: number) => {
                const {
                    product_id,
                    product_title,
                    product_discount_price,
                    product_normal_price,
                    affiliation = null,
                    coupon = null,
                    item_brand = null,
                    item_category = null,
                    item_category2 = null,
                    item_category3 = null,
                    item_category4 = null,
                    item_category5 = null,
                    item_variant = null,
                    location_id = null,
                } = item;

                // Calculate price and discount
                const isDiscount = product_discount_price > 0;
                const price = formatPrice(
                    isDiscount ? product_discount_price : product_normal_price
                );
                const discount = formatPrice(
                    isDiscount ? product_normal_price - product_discount_price : 0
                );

                return {
                    item_id: product_id,
                    item_name: product_title,
                    ...(affiliation && { affiliation }),
                    ...(coupon && { coupon }),
                    discount,
                    index: idx,
                    ...(item_brand && { item_brand }),
                    ...(item_category && { item_category }),
                    ...(item_category2 && { item_category2 }),
                    ...(item_category3 && { item_category3 }),
                    ...(item_category4 && { item_category4 }),
                    ...(item_category5 && { item_category5 }),
                    item_list_id: itemList.itemListId,
                    item_list_name: itemList.itemListName,
                    ...(item_variant && { item_variant }),
                    ...(location_id && { location_id }),
                    price,
                    quantity: 1, // Default quantity to 1
                };
            }) : [];

        // Check if items array is valid
        if (!items || !Array.isArray(items)) {
            if (!isProduction) {
                console.error("Items array is not defined or not an array.");
            }
            return;
        }

        // Filter matching product
        const filteredData = items.filter(
            (product: any) => product.item_id === item.product_id
        );

        if (filteredData.length === 0) {
            if (!isProduction) {
                console.warn("No matching product found for removal.");
            }
            return;
        }

        // Update dataLayer for Google Analytics
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null }); // Clear previous ecommerce data
        window.dataLayer.push({
            event: "select_item",
            ecommerce: {
                item_list_id: itemList.itemListId,
                item_list_name: itemList.itemListName,
                items: filteredData,
            },
        });

        // Log data in non-production environments
        if (!isProduction) {
            console.log("DataLayer updated:", {
                event: "select_item",
                ecommerce: {
                    item_list_id: itemList.itemListId,
                    item_list_name: itemList.itemListName,
                    items: filteredData,
                },
            });
        }

        // Navigate to the product page
        router.push({
            pathname: `/product/${item.product_slug}`,
            query: { store: item.store_id, id: item.product_id },
        });
    };
    return (
        <>
            <Grid container spacing={2} sx={{ display: { xs: "none", md: 'flex' } }}>
                <Grid item xs={4} sx={{ mt: "15px" }}>
                    {isLargerThan2500 ? (
                        <Box sx={{ position: "relative", width: "100%", mt: 0, height: "auto", borderRadius: "12px" }}>
                            {isLoading && (
                                <Skeleton
                                variant="rectangular"
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "12px",
                                }}
                                />
                            )}
                            <Image
                                src={dataImageCampaign?.image_extra_large || imgDefault}
                                alt={"banner-img"}
                                layout="responsive"
                                objectFit="contain"
                                height={500}
                                width={1}
                                // priority
                                style={{ 
                                    maxWidth: "100%", 
                                    height: "auto", 
                                    borderRadius: "12px", 
                                    objectFit: 'contain', 
                                }}
                                loading="lazy"
                                placeholder="empty"
                                blurDataURL={dataImageCampaign?.image_extra_large || imgDefault}
                                onLoadingComplete={() => setIsLoading(false)}
                            />
                        </Box>
                    ) : isLargerThan2160 ? (
                        <Box sx={{ position: "relative", width: "100%", mt: 0, height: "auto" }}>
                            {isLoading && (
                                <Skeleton
                                variant="rectangular"
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "12px",
                                }}
                                />
                            )}
                            <Image
                                src={dataImageCampaign?.image_extra_large || imgDefault}
                                alt={"banner-img"}
                                layout="responsive"
                                objectFit="contain"
                                height={500}
                                width={1}
                                // priority
                                style={{ 
                                    maxWidth: "100%", 
                                    height: "auto", 
                                    borderRadius: "12px",
                                    objectFit: 'contain',
                                 }}
                                loading="lazy"
                                placeholder="empty"
                                blurDataURL={dataImageCampaign?.image_extra_large || imgDefault}
                                onLoadingComplete={() => setIsLoading(false)}
                            />
                        </Box>
                    ) : isLargerThan1920 ? (
                        <Box sx={{ position: "relative", width: "100%", mt: 0, height: "auto", borderRadius: "12px" }}>
                            {isLoading && (
                                <Skeleton
                                variant="rectangular"
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "12px",
                                }}
                                />
                            )}
                            <Image
                                src={dataImageCampaign?.image_large || imgDefault}
                                alt={"banner-img"}
                                layout="responsive"
                                objectFit="contain"
                                height={500}
                                width={1}
                                // priority
                                style={{ 
                                    maxWidth: "100%", 
                                    height: "auto", 
                                    borderRadius: "12px", 
                                    objectFit: 'contain', 
                                }}
                                loading="lazy"
                                placeholder="empty"
                                blurDataURL={dataImageCampaign?.image_large || imgDefault}
                                onLoadingComplete={() => setIsLoading(false)}
                            />
                        </Box>
                    ) : (

                        <CardContent sx={{ p: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: "12px" }}>
                            {isLoading && (
                                <Skeleton
                                variant="rectangular"
                                sx={{
                                    position: "responsive",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "12px",
                                }}
                                />
                            )}
                            <Image
                                src={dataImageCampaign?.image_medium || imgDefault}
                                alt=""
                                layout="intrinsic"
                                width={500}
                                height={0}
                                style={{ width: '100%', height: '100%', borderRadius: "12px" }}
                                loading="lazy"
                                placeholder="empty"
                                blurDataURL={dataImageCampaign?.image_medium || imgDefault}
                                onLoadingComplete={() => setIsLoading(false)}
                            />
                        </CardContent>
                    )}

                </Grid>
                <Grid item xs={8}>
                    <Box
                        sx={{
                            display: 'grid',
                            gap: '16px',
                            gridTemplateColumns: 'repeat(4, 1fr)',
                            width: '100%',
                            mt: '15px',
                            '@media (min-width: 1440px)': {
                                gridTemplateColumns: 'repeat(4, 1fr)',
                            },
                            '@media (min-width: 1920px)': {
                                gridTemplateColumns: 'repeat(5, 1fr)',
                            },
                            '@media (min-width: 2160px)': {
                                gridTemplateColumns: 'repeat(6, 1fr)',
                            },
                        }}
                    >
                        {visibleProducts.map((item: any) => (
                          
                            <div
                                key={item.product_id}
                                onClick={() => handleProductClick(item, itemList)}
                                style={{ cursor: 'pointer' }}
                            >
                                <Card
                                    className="product-card-best"
                                    sx={{
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        '&:hover': {
                                            borderColor: '#f6cd64',
                                            transform: 'translateY(-2px)',
                                        },
                                        borderRadius: '14px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            textAlign: 'center',
                                            position: 'relative',
                                            width: '100%',
                                            paddingBottom: '100%',
                                        }}
                                    >
                                        {isLoading && (
                                            <Skeleton
                                            variant="rectangular"
                                            sx={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: "100%",
                                                height: "100%",
                                            }}
                                            />
                                        )}
                                        <Image
                                            src={item.product_image[0].image_full_url || imgDefault}
                                            alt={item.product_title || imgDefault}
                                            fill
                                            priority
                                            style={{ objectFit: 'cover' }}
                                            loading="lazy"
                                            placeholder="empty"
                                            blurDataURL={item.product_image[0].image_full_url || imgDefault}
                                            onLoadingComplete={() => setIsLoading(false)}
                                        />
                                    </Box>
                                    {
                                        item.product_in_campaign === null ? (
                                            <></>
                                        ) : (
                                            <img
                                                src={datacampaignComingSoon?.frame_product_full_url}
                                                alt="Frame Product"
                                                style={{
                                                    position: "absolute",
                                                    zIndex: 9,
                                                    top: "10px",
                                                    left: "0px",
                                                    width: "100%",
                                                }}
                                            />
                                        )
                                    }

                                    <CardContent
                                        sx={{
                                            height: "auto",
                                            paddingBottom: "16px"
                                        }}>
                                        <Typography
                                            sx={{
                                                mt: "10px",
                                                mb: "10px",
                                                display: '-webkit-box',
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                WebkitLineClamp: 2,
                                                whiteSpace: 'normal',
                                                color: "#717171",
                                                fontSize: "16px",
                                                lineHeight: "20px"
                                            }}
                                        >
                                            {item.product_title}
                                        </Typography>
                                        <Stack direction={"row"}>
                                            <Typography
                                                sx={{
                                                    fontSize: "18px",
                                                    lineHeight: "20px"
                                                }}
                                                className="txt-bold"
                                                gutterBottom
                                                color="secondary"
                                            >
                                                ฿{item?.product_discount_price}
                                            </Typography>
                                           
                                        </Stack>
                                        {item?.products?.product_percentage_discount > 0 ? (
                                            <Chip
                                                color="error"
                                                size="small"
                                                label={`ส่วนลด ${Math.trunc(item?.products?.product_percentage_discount)}%`}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </CardContent>
                                    <CardActions
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            mt: "auto",
                                            pl: "8px",
                                            pt: "8px",
                                            pb: "14px",
                                            pr: "8px"
                                        }}
                                    >
                                        <Box display="inline-flex" sx={{ alignItems: "center" }}>
                                            <Rating
                                                name="read-only"
                                                value={item?.product_score ?? 0}
                                                precision={0.5}
                                                readOnly
                                                size="small"
                                                sx={{ fontSize: 12 }}
                                            />
                                            <Typography
                                                variant="caption"
                                                sx={{ mr: 1, ml: 1, fontSize: 10 }}
                                            >
                                                {item.product_score ?? 0}
                                            </Typography>
                                        </Box>
                                        <Typography
                                            variant="caption"
                                            color="text.secondary"
                                            sx={{ fontSize: 10 }}
                                        >
                                            ขายแล้ว {item.product_sold} ชิ้น
                                        </Typography>
                                    </CardActions>
                                </Card>
                                </div>

                        
                        ))}
                    </Box>





                </Grid>

            </Grid>

            <Box sx={{ display: { xs: "flex", md: 'none' } }}>
                <Productcampaigncomingsoonmobile
                    datacampaignComingSoon={datacampaignComingSoon}
                />
            </Box>


        </>
    )
}